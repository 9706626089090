.cln-home {
  width: 100%;

  display: flex;
  flex-direction: column;

  &__section {
    width: 100%;
  }
}
