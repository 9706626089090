@font-face {
	font-family: "alte_din_1451_mittelschriftRg";
	src:  url("/fonts/din-alt-webfont.woff2") format("woff2"),
		    url("/fonts/din-alt-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: "alte_din_g_1451_mittelschriftRg";
	src:  url("/fonts/din-alt-g-webfont.woff2") format("woff2"),
        url("/fonts/din-alt-g-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
