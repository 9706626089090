
.cln-about {
  @include f-grid();
  margin-top: 50px;
  margin-bottom: calc(100vw * 294 / 1366);
  align-items: stretch;

  &__block {
    @include f-item(4, 1, 1);

    padding-top: 30px;
    padding-bottom: 30px;

    display: flex;
    flex-direction: column;
    align-self: center;

    &--right {
      text-align: right;
    }
  }

  &__block-header {
    margin-bottom: 8px;

    color: $purple-01;
    font-size: 40px;
  }

  &__block-img {
    width: 100%;
  }

  &__block-text {
    color: $gray-01;
    font-size: 25px;
  }
}

.cln-contact {
  @include f-grid();

  margin-top: 50px;
  margin-bottom: 120px;

  align-items: stretch;

  &__header {
    @include f-item(8, 2, 2);

    margin-bottom: 80px;

    color: $blue-01;
    font-size: 3em;
    text-align: center !important;
  }

  &__link {
    @include f-item(8, 2, 2);

    padding: 15px 10px;
    margin-bottom: 15px;

    color: $white-02;
    font-size: 1.5em;
    background-color: $blue-01;
    text-align: center;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
    align-self: normal !important;

    &:hover {
      font-weight: bold;
    }

    @include screen-start-at(m) {
      @include f-item(4);
    }
    @include screen-start-at(l) {
      @include f-item(3);

      &:first-of-type {
        @include f-item(3, 1, .5);
      }
      &:last-of-type {
        @include f-item(3, .5, 1);
      }
    }

    &--purple {
      background-color: $purple-01;
    }
  }
}

.cln-cta {
  @include f-grid();

  position: relative;
  padding-top: 50px;

  background-color: $purple-02;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;

    content: "";
    background-color: $white-01;
  }

  &__header {
    @include f-item(10, 1);

    font-size: 3.5em;
    color: $blue-01;

    @include screen-start-at (m) {
      margin-bottom: 50px;
    }
  }

  &__img {
    @include f-item(10, 1, 1);

    z-index: 1;
    margin-top: 30px;

    @include screen-start-at (m) {
      @include f-item(6, 3, 3);
    }

    &--screens {
      margin-bottom: 20px;

      @include screen-start-at (l) {
        @include f-item(4, 0, 2);
      }

    }
  }

  &__li {
    color: $gray-01;
    font-size: 2em;
    line-height: 1.4;

    &:before {
      // https://www.w3schools.com/howto/howto_css_bullet_color.asp
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: $purple-01; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
    }
  }

  &__signup {
    @include f-item(8, 2, 2);

    padding: 10px;
    margin-bottom: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $purple-01;
    color: $white-02;
    font-size: 2.5em;
    text-decoration: none;

    &:hover {
      font-weight: bold;
    }

    @include screen-start-at(m) {
      @include f-item(4, 4, 4);
    }
  }

  &__top {
    position: absolute;
    bottom: calc(100% - 1px);
    width: 100vw;
  }

  &__ul {
    @include f-item(10, 1, 1);

    margin-top: 25px;
    margin-bottom: 50px;

    list-style: none;

    @include screen-start-at(l) {
      @include f-item(5, 1, 0);
    }
  }
}

.cln-footer {
  @include f-grid();

  min-height: 200px;
  padding-top: 30px;
  padding-bottom: 30px;

  background-color: $blue-02;
  color: $white-02;

  &__header {

  }

  &__text {
    @include f-item(12);

    color: $white-02;
    text-align: center;
    line-height: 1.5;
  }
}

.cln-header {
  @include f-grid();

  background-color: $white-02;

  &__container {
    @include f-item(12);
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__link {
    height: 40px;
    margin-left: 15px;

    display: flex;
    align-items: center;

    color: $gray-01;
    font-size: 20px;
    text-decoration: none;

    &--login {
      padding: 0 20px;
      border: 2px solid $purple-01;
    }

    &--signup {
      padding: 0 20px;

      background: $purple-01;
      color: $white-02
    }
  }
}

.cln-hero {
  @include f-grid();

  position: relative;
  padding-bottom: 100px;
  padding-top: 50px;
  margin-bottom: calc(100vw * 348 / 1366);

  background-color: $blue-01;

  &__bottom {
    position: absolute;
    top: calc(100% - 1px);
    width: 100vw;
  }

  &__header {
    @include f-item(12);

    color: $white-01;
    font-size: 3em;
    text-align: center;
    margin-bottom: 10px;

    &--sub {
      font-style: italic;
      color: $gray-04;
      font-size: 1.5em;
    }
  }

  @include screen-start-at(m) {
    padding-top: 100px;
  }

  &__left {
    position: absolute;
    left: 0;
    top: calc(100% - 100% * 30 / 1366);
    width: 35vw;
  }

  &__right {
    position: absolute;
    right: 100px;
    top: calc(100% - 80px);
    width: 30vw;
  }
}
