// COLORS

$black-01: #0f0f0f;

$blue-01: #0065bf;
$blue-02: #00359f;
$blue-skype: #00aff0;

$gray-01: #444444;
$gray-02: #888888;
$gray-03: #bababa;
$gray-04: #e0e0e0;

$green-01: #2dce89;

$purple-01: #9546ff;
$purple-02: #e5d8ff;

$red-01: #f5365c;
$red-02: #ffb6c1;

$yellow-01: #ffd600;

$white-01: #ffffff;
$white-02: #f7fafc;

// FONTS
$din-1: alte_din_1451_mittelschriftRg;
$din-2: alte_din_1451_mittelschriftRg;
